import { v4 as uuidv4 } from 'uuid';
import * as REGEX from '~/constants/regex';
import Cookie from '~/components/services/Cookie';

export default function getUserId(): string {
  let userId = Cookie.get('user_id');

  if (typeof userId !== 'string' || !REGEX.UUID_V4.test(userId)) {
    userId = uuidv4();
    Cookie.set('user_id', userId);
  }

  return userId;
}
