import { PATHS } from '~/constants/nav-items';

export const getNavItems = () => [
    PATHS.PRODUCT,
  {
    ...PATHS.RESOURCES,
    active: false,
    children: {
      'Newsroom': [
        PATHS.IN_THE_NEWS,
        PATHS.PRESS_RELEASES,
        PATHS.PRODUCT_REVIEWS,
      ],
      'Resource_Library': [
        PATHS.WHITE_PAPERS,
        { ...PATHS.TECHNICAL_PAPERS, i18nKey: 'Solution_Briefs' },
        PATHS.ANALYST_REPORTS,
        PATHS.CASE_STUDIES,
      ]
    },
  },
  PATHS.PARTNERS,
  PATHS.BLOG,
  {
    ...PATHS.GET_HELP,
    active: false,
    children: [
      PATHS.SUPPORT,
      { ...PATHS.INSTALLATION, i18nKey: 'Installation_Guide' },
      { ...PATHS.GET_HELP, i18nKey: 'Help_Center' },
      PATHS.CONTACTS_US,
    ],
  },
];
