import Cookie from '~/components/services/Cookie';
import { useCookieLocale } from '#i18n';

export default defineNuxtRouteMiddleware(() => {
  if (process.server) return;

  const nuxt = useNuxtApp();
  const cookieLocale = useCookieLocale();
  const i18nLocale = nuxt.$i18n.locale.value;

  if (!cookieLocale.value) {
    Cookie.set('locale', i18nLocale);
  }
});
