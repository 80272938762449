<template>
  <form
    data-component-name="TheSearchBar"
    @submit.prevent="submitHandler"
  >
    <AppInput
      v-model.trim="searchValue"
      ref="inputRef"
      :label="$t('Search')"
      type="search"
      name="search"
    />

    <Buttons.Regular
      :disabled="!searchValue.length"
      type="submit"
    >
      <SvgArrowForward />
    </Buttons.Regular>
  </form>
</template>

<script setup lang="ts">
// components
import Buttons from '~/components/redesign/Buttons';
import AppInput from '~/components/redesign/AppInput.vue';

// types
import type { ComponentInstance } from 'vue';

const emit = defineEmits<{ search: [value: string] }>();

const searchValue = ref('');
const inputRef = ref<null | ComponentInstance<typeof AppInput>>(null);

const submitHandler = () => {
  emit('search', searchValue.value);

  if (inputRef.value) {
    inputRef.value.blur();
  }
};

onMounted(() => {
  if (inputRef.value) {
    inputRef.value.focus();
  }
});
</script>

<style scoped lang="scss">
@import "$/mixins/flex";

[data-component-name="TheSearchBar"] {
  @include flex-stretch-sb;
}
</style>
