<template>
  <div data-component-name="BreakingNewsBanner">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@import "$/functions/token";
@import "$/mixins/typography";
@import "$/mixins/media";

[data-component-name="BreakingNewsBanner"] {
  padding: 0.5rem 5rem;
  background-color: token('surf-cont-prim-action');

  color: token('text-reversed');
  @include body-1;

  text-align: center;
  white-space: nowrap;
  overflow-x: auto;

  @include mobile {
    padding: 0.5rem 1rem;
  }
}
</style>
