<template>
  <div data-page-name="error">
    <BreakingNewsBanner v-if="isPageMayHaveBanner">
      <NuxtLink :to="localePath('/welcome-192tb-ootbi/website-banner/?utm_source=website-banner')">
        {{ $t('BreakingNewsBanner') }}
      </NuxtLink>
    </BreakingNewsBanner>

    <TheHeader />

    <main>
      <section>
        <AppWrapper>
          <div class="left">
            <h1>{{ $t('Got_Veeam') }}?</h1>
            <p>{{ $t('error.description') }}</p>

            <Buttons.Link :to="localePathWithSlash('/')">
              {{ $t('Visit_Home_Page') }}
              <SvgArrowForward />
            </Buttons.Link>
          </div>

          <div class="right">
            <NuxtImg
              src="/redesign/images/ootbi/ootbi-cartoon-404.webp"
              alt="Object First cartoon character with magnifying glass between two numbers four"

              width="697"
              height="417"

              sizes="xs:328px md:697px"
              densities="x1 x2"
              format="webp"
            />
          </div>
        </AppWrapper>
      </section>

      <ClientOnly>
        <div
          v-if="env === 'development' && error"
          style="border:1px solid red;max-width:80%;max-height:300px;overflow:auto;margin:0 auto;"
        >
          <pre>{{ error }}</pre>

          <div
            v-if="error.stack"
            v-html="error.stack"
            style="color: red;"
          />
        </div>
      </ClientOnly>
    </main>

    <TheFooter />
  </div>

  <AppOverlay id="overlay" v-bind="overlay">
    <transition name="fade" mode="out-in">
      <TheSearch v-if="isSearchVisible" @close="closeSearch" />
    </transition>

    <transition name="slide-from-right">
      <SidebarMobile v-if="isMobileMenuVisible" @close="closeMobileMenu" />
    </transition>
  </AppOverlay>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useLocalePath } from '#i18n';
import type { NuxtError } from '#app';

// components
import TheHeader from '~/components/redesign/TheHeader.vue';
import BreakingNewsBanner from '~/components/redesign/BreakingNewsBanner.vue';
import AppWrapper from '~/components/redesign/AppWrapper.vue';
import Buttons from '~/components/redesign/Buttons';
import TheFooter from '~/components/redesign/TheFooter.vue';
import AppOverlay from '~/components/redesign/AppOverlay.vue';

// stores
import { useVeeamOnStore } from '~/stores/veeamon';

// composable
import useReport from '~/composables/useReport';
import onRouteChange from '~/composables/onRouteChange';

// stores
import useUIStore from '~/stores/ui';
import TheSearch from '~/components/redesign/TheSearch/Container.vue';
import SidebarMobile from '~/components/redesign/SidebarMobile.vue';

const { error } = defineProps<{ error: NuxtError }>();

const uiStore = useUIStore();
const { setOverlay, setMobileMenuVisibility, setSearchVisibility } = uiStore;
const { isMobileMenuVisible, isSearchVisible, isScrollLocked, overlay } = storeToRefs(uiStore);

const closeMobileMenu = () => {
  setOverlay({ visible: false });
  setMobileMenuVisibility(false);
};

const closeSearch = () => {
  setOverlay({ visible: false });
  setSearchVisibility(false);
};

onRouteChange(() => setMobileMenuVisibility(false));

const localePath = useLocalePath();
const localePathWithSlash = useLocalePathWithSlash();
const { public: { env, api_v2: baseURL } } = useRuntimeConfig();

const { isPageMayHaveBanner } = storeToRefs(useVeeamOnStore());

useHead({
  bodyAttrs: {
    class: {
      'no-scroll': isScrollLocked,
    },
  },
});
useReport(error, document.location.href);
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/mixins/media";
@import "$/mixins/common";
@import "$/functions/token";

[data-page-name="error"] {
  padding-top: 7.5rem;

  @include mobile {
    padding-top: 7.0625rem;
  }

  main {
    padding: 8rem 0;

    @include tablet {
      padding: 5rem 0 8rem 0;
    }

    @include mobile {
      padding: 2.5rem 0 5rem 0;
    }

    section {
      @include flex-start-sb;
      gap: 0 2rem;

      @include tablet {
        flex-direction: column;
        gap: 1.5rem 0;
      }

      @include mobile {
        gap: 2.5rem 0;
      }

      .left {
        padding-top: 6.94rem;

        @include tablet {
          padding-top: 0;
        }

        @include mobile {
          width: 100%;
        }

        h1 {
          @include h2;
          margin-bottom: 1rem;

          @include mobile {
            margin-bottom: 0.5rem;
          }
        }

        p {
          @include body-2;
          margin-bottom: 2rem;

          @include mobile {
            margin-bottom: 1.5rem;
          }
        }

        a {
          display: block;
          width: max-content;

          @include mobile {
            width: 100%;
          }
        }
      }

      .right {
        margin: 0 auto;

        @include mobile {
          width: 100%;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  [data-component-name="TheHeader"] {
    position: fixed;
    left: 0;
    top: 3.25rem;
    right: 0;
    z-index: 6;

    @include mobile {
      top: 2.6875rem;
    }
  }

  [data-component-name="BreakingNewsBanner"] {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 5;
  }
}

[data-component-name="SidebarMobile"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

[data-component-name="TheSearch"] {
  position: absolute;
  top: 5.5rem;

  @include fluid-max-width(75rem);
  max-height: calc(100dvh - (5.5rem + 1.5rem));

  @include tablet {
    @include fluid-max-width(calc(100% - 4rem));
  }

  @include mobile {
    top: 3.5rem;

    @include fluid-max-width(100%);
    max-height: calc(100dvh - (3.5rem + 1rem));
  }
}
</style>
