import { readonly } from 'vue';
import { defineStore } from 'pinia';

// types
import type { IpInfo } from '~/types/api-responses';

export const useCommonStore = defineStore('common', () => {
  const ipInfo = ref<null | IpInfo>(null);

  const requestIpInfo = () => {
    const { public: { apiIpInfo } } = useRuntimeConfig();

    return useFetch<IpInfo>(apiIpInfo, {
      method: 'GET',
      lazy: true,
      server: false,
    });
  };

  const setIpInfo = (info: IpInfo | null) => {
    ipInfo.value = info;
  };

  return {
    ipInfo: readonly(ipInfo),
    setIpInfo,
    requestIpInfo,
  };
});
