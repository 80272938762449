import { PATHS } from '~/constants';
import { PostType, type BlogArticle } from '~/types';

export const getArticleLink = (article: BlogArticle) => {
  const { type, slug, categories: [category] = [] } = article;

  if (!type || !slug || !category) return '/';

  switch (type) {
    case PostType.POST: {
      return `${PATHS.BLOG.path}${slug}/`;
    }

    case PostType.RESOURCE: {
      return `${PATHS.RESOURCES.path}${category.slug}/${slug}/`;
    }

    case PostType.NEWSROOM: {
      return `${PATHS.NEWSROOM.path}${category.slug}/${slug}/`;
    }

    case PostType.GUIDE: {
      return `${PATHS.GUIDES.path}${category.slug}/${slug}/`;
    }

    case PostType.SECURITY: {
      return `/security/${category.slug}/${slug}/`;
    }

    default: {
      return '/';
    }
  }
};
