export enum PostStatus {
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}

export enum PostType {
  POST = 'POST',
  RESOURCE = 'RESOURCE',
  NEWSROOM = 'NEWSROOM',
  GUIDE = 'GUIDE',
  SECURITY = 'SECURITY',
  LEGAL = 'LEGAL',
  INSTALLATION = 'INSTALLATION'
}

export enum PostPreviewType {
  DEFAULT = 'DEFAULT',
  DEFAULT_SETTINGS = 'DEFAULT_SETTINGS',
  WITH_PICTURE = 'WITH_PICTURE',
}

export enum PostPreviewButtonType {
  POST_LINK = 'POST_LINK',

  LINK_ICON = 'LINK_ICON',
  LINK_TEXT = 'LINK_TEXT',

  ACTION_TEXT = 'ACTION_TEXT',
  ACTION_ICON = 'ACTION_ICON',
}

export enum PostPreviewButtonAction {
  WHITE_PAPER_POPUP = 'WHITE_PAPER_POPUP',
}

export enum PostPreviewAuthorType {
  DEFAULT = 'DEFAULT',
  EMPTY = 'EMPTY',
  IMAGE_TEXT = 'IMAGE_TEXT',
}
